import {React, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
function Landing() {
  const pricing = useRef(null)
  const benefits = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const executeScrollPricing = () => pricing.current.scrollIntoView()   
  const executeScrollBenefits = () => benefits.current.scrollIntoView()   
  return (    
    <div data-theme="cmyk">
    <header className="bg-base-100 text-primary p-4 flex justify-between items-center">
      <Link to="/" className="text-2xl font-bold flex items-center">
        {/* <i className="fas fa-rocket text-accent mr-2"></i> */}
        <div className="flex justify-center items-center p-2"> <img src="rocket_style3.png" alt="Logo" className="w-12 h-auto" /> </div>        
        Tech Startup Templates
      </Link>
      <button className="md:hidden text-primary" onClick={toggleMenu}>
        <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'} text-xl`}></i>
      </button>
      <nav className={`absolute top-16 left-0 w-full bg-base-100 shadow-md md:static md:w-auto md:shadow-none md:flex ${isMenuOpen ? 'block' : 'hidden'}`}>
        <ul className="md:flex space-y-4 md:space-y-0 md:space-x-6 text-center p-4 md:p-0">
          <li><Link to="/" className="text-primary hover:text-accent">Home</Link></li>
          <li><Link onClick={executeScrollBenefits} className="text-primary hover:text-accent">Benefits</Link></li>
          <li><Link onClick={executeScrollPricing} className="text-primary hover:text-accent">Pricing</Link></li>
          {/* <li><Link to="/contact" className="text-primary hover:text-accent">Contact</Link></li> */}
        </ul>
      </nav>
    </header> 
    <div data-theme="cupcake"> 
    <section className="bg-base-100 text-primary text-center py-20 px-4">
      <h1 className="text-4xl md:text-5xl font-bold">Try a Tech Startup Idea Today</h1>
      <p className="text-accent mt-4 max-w-md mx-auto">
      Bring your startup to life with our all-inclusive templates. Get everything you need, from landing pages to tech setup support, to quickly launch your MVP and concentrate on growth.
      </p>
      <button className="mt-6 btn btn-accent" onClick={executeScrollPricing}>
        <i className="fas fa-rocket mr-2"></i> Get Started
      </button>
    </section>
    <section ref={benefits} className="bg-base-300 text-neutral py-16 px-4">
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-3xl font-bold">All Our Templates Will Offer</h2>
        <div className="mt-8 flex flex-wrap">
          {/* Benefit 1: Landing Page */}
          <div className="w-full md:w-1/2 lg:w-1/4 p-6">
            <div className="flex flex-col items-center bg-primary text-neutral p-6 rounded-lg h-full">
              <i className="fas fa-file-alt text-accent text-3xl mb-4"></i>
              <h3 className="text-xl font-bold">Landing Page</h3>
              <p className="mt-2 text-sm">
                Ready to use, stunning, responsive landing pages to capture your audience's attention and drive conversions.
              </p>
            </div>
          </div>

          {/* Benefit 2: Source Code */}
          <div className="w-full md:w-1/2 lg:w-1/4 p-6">
            <div className="flex flex-col items-center bg-primary text-neutral p-6 rounded-lg h-full">
              <i className="fas fa-code text-accent text-3xl mb-4"></i>
              <h3 className="text-xl font-bold">Source Code</h3>
              <p className="mt-2 text-sm">
              We provide the source code of the website, including backend and frontend. Access clean, well-documented source code that’s easy to customize and evolve with your business.
              </p>
            </div>
          </div>

          {/* Benefit 3: Payment & Login Integration */}
          <div className="w-full md:w-1/2 lg:w-1/4 p-6">
            <div className="flex flex-col items-center bg-primary text-neutral p-6 rounded-lg h-full">
              <i className="fas fa-credit-card text-accent text-3xl mb-4"></i>
              <h3 className="text-xl font-bold">Payment & Login Integration</h3>
              <p className="mt-2 text-sm">
                Seamlessly integrate payment gateways and login systems to provide a secure and user-friendly experience.
              </p>
            </div>
          </div>

          {/* Benefit 4: Setup Support */}
          <div className="w-full md:w-1/2 lg:w-1/4 p-6">
            <div className="flex flex-col items-center bg-primary text-neutral p-6 rounded-lg h-full">
              <i className="fas fa-headset text-accent text-3xl mb-4"></i>
              <h3 className="text-xl font-bold">Setup Support</h3>
              <p className="mt-2 text-sm">
                Get expert setup support to ensure your products are configured and running smoothly from day one.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section ref={pricing} className="bg-base-200 text-primary text-center py-20 px-4">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* Header Section */}
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            Start now your AI business
          </h2>
          <p className="mt-6 text-lg leading-8 text-primary-content">
          This online business allows customers to: Create web apps effortlessly with natural language input—no coding required. Easily embed their apps into websites and integrate AI chat assistants for enhanced user engagement. Plus, share and monetize their creations with just a few clicks!
          </p>
        </div>
        {/* Pricing Details */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-primary">
              Lifetime ownership of an AI app generator SaaS
            </h3>
            <p className="mt-6 text-base leading-7 text-primary-content">
            This is an excellent opportunity to start an AI business. Check our demo website here: <a target='_blank' href='https://www.fireaiapp.com'>https://www.fireaiapp.com</a> 
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-accent">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100"></div>
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-secondary sm:grid-cols-2 sm:gap-6">
              <li className="flex gap-x-3">
                <i className="fas fa-check text-accent"></i>
                Landing Page
              </li>
              <li className="flex gap-x-3">
                <i className="fas fa-check text-accent"></i>
                Source code
              </li>
              <li className="flex gap-x-3">
                <i className="fas fa-check text-accent"></i>
                Payment & Login Integration
              </li>
              <li className="flex gap-x-3">
                <i className="fas fa-check text-accent"></i>
                Setup support
              </li>
            </ul>
          </div>
          {/* Pricing Box */}
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-base-100 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-primary-content">
                  Pay once, own it forever
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-primary">$499</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-secondary">USD</span>
                </p>
                {/* <div className="mt-6">
                <stripe-buy-button className="mt-6 btn btn-accent"
                  buy-button-id="buy_btn_1PsrYK02gaSUJqipURKevbmi"
                  publishable-key="pk_live_51PspVi02gaSUJqipNwbwFhg47xjQpX4QGzlZA9iwny72j90SkGui3P6asp8VKZ10QUwyO5fNbyjXcesrA4P0a3FH00CumontLX"
                >

                </stripe-buy-button>     
                </div>              */}
                <a target="_blank" href='https://buy.stripe.com/3csdS269OfXu4Le288'>
                <button className="mt-6 btn btn-accent">
                  <i className="fas fa-rocket mr-2"></i> Buy Now
                </button>
                </a>
                <p className="mt-6 text-xs leading-5 text-secondary">
                You are one step to your financial freedom
                </p>
                {/* <stripe-pricing-table 
                  pricing-table-id="prctbl_1PsqmL02gaSUJqipPWUWEAtr"
                  publishable-key="pk_live_51PspVi02gaSUJqipNwbwFhg47xjQpX4QGzlZA9iwny72j90SkGui3P6asp8VKZ10QUwyO5fNbyjXcesrA4P0a3FH00CumontLX">
                </stripe-pricing-table>   */}

                            
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  
    </div>    
    <footer className="bg-neutral text-neutral-content py-4 text-center">
      <div className="flex justify-center space-x-4">
        <a href="mailto: techstartuptemplates@gmail.com" className="text-accent"><i class="fa-solid fa-envelope"></i></a>
        <a href="#" className="text-accent"><i className="fab fa-facebook-f"></i></a>
        <a href="#" className="text-accent"><i className="fab fa-twitter"></i></a>
        <a href="#" className="text-accent"><i className="fab fa-linkedin-in"></i></a>
      </div>
      <p className="mt-4">&copy; 2024 Tech Startup Templates. All rights reserved.</p>
    </footer>         
    </div> 
  );
} 

export default Landing;
